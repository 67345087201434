import { Layout, Collapse, Card, Row, Col, Toast, TextArea, Tag, Modal, Descriptions, ButtonGroup, Nav, Typography, Tabs, Input, Table, TabPane, Button, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';
import { IconBell, IconPlus, IconMinus, IconRefresh2, IconHelpCircle, IconImport, IconBytedanceLogo, IconHome, IconMore, IconHistogram, IconLive, IconSetting } from '@douyinfe/semi-icons';

import { ethers, Wallet } from 'ethers'


import moment from 'moment';

import React, { Component, useState, useEffect } from 'react';



const App = (props) => {

    const { Header, Footer, Sider, Content } = Layout;
    const { Column } = Table;
    const { Text } = Typography;

    const [address, setAddress] = useState("");
    const [monitorTextArea, setMonitorTextArea] = useState("");
    const [monitorAddr, setMonitorAddr] = useState([]);
    const [genWalletLogs, setGenWalletLogs] = useState([]);
    const [logs, setLogs] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [itemsData, setItemsData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);

    const [trace, setTrace] = useState({});

    const [ethData, setEthData] = useState([]);
    const [walletStatistics, setWalletStatistics] = useState([]);

    const [currTab, setCurrTab] = useState("pre");

    const [visible, setVisible] = useState(false);
    const [showGenWallet, setShowGenWallet] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const [showTransItem, setShowTransItem] = useState(false);

    const [genWalletCount, setGenWalletCount] = useState(10);
    const [bindAddress, setBindAddress] = useState("");
    const [collectGoldAddress, setCollectGoldAddress] = useState("");
    const [collectGoldCount, setCollectGoldCount] = useState("");
    const [distributeGoldAddress, setDistributeGoldAddress] = useState("");
    const [distributeGoldArray, setDistributeGoldArray] = useState("");
    const [distributeGoldCount, setDistributeGoldCount] = useState("");

    const [collectFuelAddress, setCollectFuelAddress] = useState("");
    const [collectFuelCount, setCollectFuelCount] = useState("");
    const [distributeFuelArray, setDistributeFuelArray] = useState("");
    const [distributeFuelAddress, setDistributeFuelAddress] = useState("");
    const [distributeFuelCount, setDistributeFuelCount] = useState("");

    const [collectMGBAddress, setCollectMGBAddress] = useState("");
    const [collectMGBCount, setCollectMGBCount] = useState("");

    const [buildArray, setBuildArray] = useState("");
    const [assetArray, setAssetArray] = useState("");

    const [randomSec, setRandomSec] = useState(30);





    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.ninicoin.io');


    const sign = async (privateKey, time) => {
        const signer = new ethers.Wallet(privateKey, provider);
        const signedMessage = await signer.signMessage("time" + time)
        return signedMessage;
    }

    /**
     * 登陆
     * @param {*} address 
     * @param {*} time 
     * @param {*} sign 
     * @returns 
     */
    const login = async (address, time, sign) => {

        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");

        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Account/newLogin", requestOptions);

        const data = await res.text()

        return JSON.parse(data).data.token;
    }

    /**
     * 绑定
     * @param {*} address 
     * @param {*} time 
     * @param {*} sign 
     * @param {*} referrer 
     * @param {*} token 
     * @returns 
     */
    const bind = async (address, time, sign, referrer, token) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign,
            "building_id": 1,
            "referrer": referrer,
            "token": token
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Mgb/bindAddress", requestOptions)
        const data = await res.text()
        return decodedStr(data);
    }

    /**
     * 转账
     * @param {*} address 
     * @param {*} time 
     * @param {*} sign 
     * @param {*} asset_id 
     * @param {*} num 
     * @param {*} opposite_address 
     * @param {*} token 
     * @returns 
     */
    const transfer = async (address, time, sign, asset_id, num, opposite_address, token) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign,
            "asset_id": asset_id,
            "opposite_address": opposite_address,
            "num": num,
            "token": token
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Game/transfer", requestOptions)
        const data = await res.text()
        return decodedStr(data);
    }

    /**
     * 构建楼层
     * @param {*} address 
     * @param {*} time 
     * @param {*} sign 
     * @param {*} lan 
     * @param {*} token 
     * @returns 
     */
    const build = async (address, time, sign, token, lan) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign,
            "lan": lan,
            "token": token
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Game/build", requestOptions)
        const data = await res.text()
        return decodedStr(data);
    }



    /**
     * 种菜
     * @param {*} address 
     * @param {*} time 
     * @param {*} sign 
     * @param {*} token 
     * @param {*} lan 
     * @returns 
     */
    const zhong = async (address, time, sign, token, lan) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign,
            "building_id": lan,
            "lan": lan,
            "token": token
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Game/replenishment", requestOptions)
        const data = await res.text()
        return decodedStr(data);
    }

    const shou = async (address, time, sign, token, lan) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign,
            "building_id": lan,
            "lan": lan,
            "token": token
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Game/receive", requestOptions)
        const data = await res.text()
        return decodedStr(data);
    }

    const asset = async (address, time, sign, token, lan) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign,
            "lan": lan,
            "token": token
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Game/asset", requestOptions)
        const data = await res.text()
        return decodedStr(data);
    }

    const buildLC = async (address, time, sign, token, lan) => {
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");
        var raw = JSON.stringify({
            "address": address,
            "time": time,
            "sign": sign,
            "lan": lan,
            "token": token
        });
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };
        const res = await fetch("https://api.mini-game.co/Game/build", requestOptions)
        const data = await res.text()
        return decodedStr(data);
    }

    const decodedStr = (str) => {
        const decodedStr = str.replace(/\\u([\d\w]{4})/gi, (match, grp) => {
            return String.fromCharCode(parseInt(grp, 16));
        });
        return decodedStr;
    }

    /**
     * 种菜
     * @param {*}} lan 
     * @returns 
     */
    const fetchZhong = async (lan) => {
        if (localStorage.getItem("wallet") === null) {
            Toast.warning("请先导入钱包");
            return;
        }
        setLoading(true);
        const walletList = JSON.parse(localStorage.getItem("wallet"));
        let tempList = [];
        for (const item of walletList) {

            const settingSec = randomSec * 1000;

            const randomM = parseInt(Math.random() * settingSec);

            setTimeout(async () => {
                const time = new Date().getTime();
                // 计算签名
                const message = await sign(item.privateKey, time);

                console.log(message);

                // 登陆
                const token = await login(item.address, time, message);

                // 种菜
                const result = await zhong(item.address, time, message, token, lan);

                // 打印日志

                setLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【种菜】层数: [${lan}] 钱包: ${item.address}====${result}\n`);

            }, randomM)
        }
        setLoading(false);
    }


    /**
     * 收菜
     * @param {*} lan 
     * @returns 
     */
    const fetchShou = async (lan) => {
        if (localStorage.getItem("wallet") === null) {
            Toast.warning("请先导入钱包");
            return;
        }
        setLoading(true);
        const walletList = JSON.parse(localStorage.getItem("wallet"));
        for (const item of walletList) {

            const settingSec = randomSec * 1000;

            const randomM = parseInt(Math.random() * settingSec);

            setTimeout(async () => {
                const time = new Date().getTime();
                // 计算签名
                const message = await sign(item.privateKey, time);

                console.log(message);

                // 登陆
                const token = await login(item.address, time, message);

                // 种菜
                const result = await shou(item.address, time, message, token, lan);

                // 打印日志

                setLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【收菜】层数: [${lan}] 钱包: ${item.address}====${result}\n`);

            }, randomM)
        }
        setLoading(false);
    }



    const showDialog = (tab) => {
        setVisible(true);
        setCurrTab(tab);
    };

    const showGenWalletDialog = () => {
        setShowGenWallet(true);
    };


    const showWallteDialog = () => {
        if (localStorage.getItem("wallet") === null) {
            Toast.warning("请先导入钱包");
            return;
        }
        setShowWallet(true);
    };

    const showTransItemDialog = (items) => {
        setShowTransItem(true);
        setItemsData(items);
    };

    const handleOk = () => {
        setVisible(false);
        const lines = monitorTextArea.split("\n");
        let walletList = [];
        for (let i = 0; i < lines.length; i++) {
            const wallet = lines[i].split(",");
            walletList.push({ address: wallet[0], privateKey: wallet[1] })
        }

        if (currTab === 'pre') {
            localStorage.setItem("pre_wallet", JSON.stringify(walletList))
        } else {
            localStorage.setItem("wallet", JSON.stringify(walletList))
        }

        Toast.success('已导入' + walletList.length + "个钱包")
    };


    /**
     * 收集资产
     * @returns 
     */
    const collectAsset = async (channel) => {

        if (localStorage.getItem("pre_wallet") === null) {
            Toast.warning("请先导入钱包");
            return;
        }

        const preWallets = JSON.parse(localStorage.getItem("pre_wallet"));

        for (const item of preWallets) {

            const settingSec = randomSec * 1000;

            const randomM = parseInt(Math.random() * settingSec);

            setTimeout(async () => {

                const time = new Date().getTime();

                // 计算签名
                const message = await sign(item.privateKey, time);

                // 登陆
                const token = await login(item.address, time, message);

                let assetId = 1;
                let assetName = "金币";
                let assetCount = 1;
                let assetAddress = "";

                if (channel === 'gold') {
                    // 绑定
                    const bindRes = await bind(item.address, time, message, bindAddress, token);
                    setGenWalletLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【绑定钱包】${item.address}====${bindRes}\n`);
                    assetCount = collectGoldCount;
                    assetAddress = collectGoldAddress;
                } else if (channel === 'fuel') {
                    assetId = 4;
                    assetName = "燃料";
                    assetCount = collectFuelCount;
                    assetAddress = collectFuelAddress;
                } else if (channel === 'mgb') {
                    assetId = 3;
                    assetName = "MGB";
                    assetCount = collectMGBCount;
                    assetAddress = collectMGBAddress;
                }

                setTimeout(async () => {
                    // 资产读取
                    const assetRes = await asset(item.address, time, message, token, assetId)
                    setGenWalletLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【资产读取】${item.address}====${assetRes}\n`);

                    // 转账
                    const transferRes = await transfer(item.address, time, message, assetId, assetCount, assetAddress, token);
                    setGenWalletLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【归集${assetName}】${item.address}====${transferRes}\n`);
                }, 8000)

            }, randomM)

        }
    }

    /**
     * 资产分发
     * @param {*} channel 
     */
    const distributeAsset = async (channel) => {

        const time = new Date().getTime();

        let address = "";

        let privateKey = "";
        

        let assetId = 1;
        let assetName = "金币";
        let assetCount = 1;
        let assetArray = [];


        if (channel === 'gold') {
            address = distributeGoldAddress.split(",")[0];
            privateKey = distributeGoldAddress.split(",")[1];
            assetCount = distributeGoldCount;
            assetArray = distributeGoldArray.split("\n");
        } else if (channel === 'fuel') {
            address = distributeFuelAddress.split(",")[0];
            privateKey = distributeFuelAddress.split(",")[1];
            assetId = 4;
            assetName = "燃料";
            assetCount = distributeFuelCount;
            assetArray = distributeFuelArray.split("\n");
        }

        // 计算签名
        const message = await sign(privateKey, time);

        // 登陆
        const token = await login(address, time, message);

        for (const item of assetArray) {

            const settingSec = randomSec * 1000;

            const randomM = parseInt(Math.random() * settingSec);

            setTimeout(async () => {
                // 转账
                const transferRes = await transfer(address, time, message, assetId, assetCount, item, token);
                setGenWalletLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【分发${assetName}】${address}=>${item}====${transferRes}\n`);
            }, randomM)
        }
    }

    /**
     * 楼层构建
     * @param {*} lan 
     */
    const buildLou = async (lan) => {

        const buildList = buildArray.split("\n");

        for (const item of buildList) {

            const settingSec = randomSec * 1000;

            const randomM = parseInt(Math.random() * settingSec);

            const walletAddress = item.split(",")[0];
            const walletPriKeys = item.split(",")[1];

            setTimeout(async () => {

                const time = new Date().getTime();

                // 计算签名
                const message = await sign(walletPriKeys, time);

                // 登陆
                const token = await login(walletAddress, time, message);

                // 构建楼层
                const buildRes = await buildLC(walletAddress, time, message, token, lan)
                setGenWalletLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【楼层${lan}构建】${walletAddress}====${buildRes}\n`);
            }, randomM)
        }
    }

     /**
     * 查询资产
     * @param {*} lan 
     */
      const queryAsset = async (lan) => {

        const assetList = assetArray.split("\n");
        

        for (const item of assetList) {

            const settingSec = randomSec * 1000;

            const randomM = parseInt(Math.random() * settingSec);

            const walletAddress = item.split(",")[0];
            const walletPriKeys = item.split(",")[1];



            setTimeout(async () => {
                const time = new Date().getTime();

                // 计算签名
                const message = await sign(walletPriKeys, time);

                // 登陆
                const token = await login(walletAddress, time, message);

                // 构建楼层
                const assetRes = await asset(walletAddress, time, message, token, lan)
                setGenWalletLogs(prevLogs => prevLogs + `${moment().format('YYYY-MM-DD HH:mm:ss')}【资产查询】${walletAddress}====${assetRes}\n`);
            }, randomM)
        }
    }
    


    /**
     * 生成钱包
     */
    const genWallet = () => {
        for (let i = 0; i < genWalletCount; i++) {
            const wallet = Wallet.createRandom();
            setGenWalletLogs(prevLogs => prevLogs + `${wallet.address},${wallet.privateKey}\n`);
        }
    }

    const handleCancel = () => {
        setVisible(false);
        setShowWallet(false);
        setShowTransItem(false);
        setShowGenWallet(false);
    };

    const handleAfterClose = () => {
        console.log('After Close callback executed');
    };

    const clearPreLogs = () => {
        setGenWalletLogs([]);
    }

    

    return (
        <>
            <Modal
                title="导入钱包"
                visible={visible}
                maskClosable={false}
                width={500}
                onOk={() => handleOk(currTab)}
                afterClose={handleAfterClose} //>=1.16.0
                onCancel={handleCancel}
                closeOnEsc={true}
            >
                一行一钱包,格式：钱包地址,私钥
                <br />
                <br />
                例如：0x0999....9999,xxx
                <br />
                <br />
                <TextArea onChange={setMonitorTextArea} value={monitorTextArea} />
            </Modal>
            <Modal
                title="查看已导入钱包"
                visible={showWallet}
                maskClosable={false}
                width={500}
                onOk={handleOk}
                afterClose={handleAfterClose} //>=1.16.0
                onCancel={handleCancel}
                closeOnEsc={true}
                footer={null}
            >
                <Table dataSource={JSON.parse(localStorage.getItem("wallet"))} pagination={false}>
                    <Column title="钱包" dataIndex="address" key="address" />
                    <Column title="私钥" dataIndex="privateKey" key="privateKey" />
                </Table>
            </Modal>

            <Modal
                title="生成钱包"
                visible={showGenWallet}
                maskClosable={false}
                width={500}
                onOk={genWallet}
                afterClose={handleAfterClose} //>=1.16.0
                onCancel={handleCancel}
                closeOnEsc={true}
            >
            </Modal>

            <Collapse expandIcon={<IconPlus />} collapseIcon={<IconMinus />}>
                <Collapse.Panel header="数据配置" itemKey="1">
                    <Card title='基础信息配置' style={{ marginBottom: 20 }}>

                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Input onChange={(e) => setGenWalletCount(e)} value={genWalletCount} placeholder='生成钱包数量'></Input>
                            </Col>
                            <Col span={6}>
                                <Input onChange={(e) => setRandomSec(e)} value={randomSec} placeholder='随机时间范围（秒）'></Input>
                            </Col>
                            <Col span={6}>
                                <Input onChange={(e) => setBindAddress(e)} value={bindAddress} placeholder='绑定地址'></Input>
                            </Col>

                            <Col span={8}>
                                <TextArea onChange={(e) => setBuildArray(e)} value={buildArray} placeholder='构建楼层地址,一行一个(地址,私钥)'></TextArea>
                            </Col>

                            <Col span={8}>
                                <TextArea onChange={(e) => setAssetArray(e)} value={assetArray} placeholder='资产查询地址,一行一个(地址,私钥)'></TextArea>
                            </Col>
                        </Row>

                    </Card>

                    <Card title='金币配置' style={{ marginBottom: 20 }}>

                        <Row gutter={[16, 16]}>

                            <Col span={6}>
                                <Input onChange={(e) => setCollectGoldAddress(e)} value={collectGoldAddress} placeholder='金币归集地址'></Input>
                            </Col>
                            <Col span={6}>
                                <Input onChange={(e) => setCollectGoldCount(e)} value={collectGoldCount} placeholder='金币归集数量'></Input>
                            </Col>
                            <Col span={6}>
                                <Input onChange={(e) => setDistributeGoldAddress(e)} value={distributeGoldAddress} placeholder='金币分发大钱包(地址,私钥)'></Input>
                            </Col>

                            <Col span={6}>
                                <Input onChange={(e) => setDistributeGoldCount(e)} value={distributeGoldCount} placeholder='金币分发数量'></Input>
                            </Col>

                            <Col span={6}>
                                <TextArea onChange={(e) => setDistributeGoldArray(e)} value={distributeGoldArray} placeholder='分发地址, 一行一个'></TextArea>
                            </Col>
                        </Row>

                    </Card>

                    <Card title='燃料配置' style={{ marginBottom: 20 }}>

                        <Row gutter={[16, 16]}>

                            <Col span={6}>
                                <Input onChange={(e) => setCollectFuelAddress(e)} value={collectFuelAddress} placeholder='燃料归集地址'></Input>
                            </Col>

                            <Col span={6}>
                                <Input onChange={(e) => setCollectFuelCount(e)} value={collectFuelCount} placeholder='燃料归集数量'></Input>
                            </Col>

                            <Col span={6}>
                                <Input onChange={(e) => setDistributeFuelAddress(e)} value={distributeFuelAddress} placeholder='燃料分发大钱包(地址,私钥)'></Input>
                            </Col>

                            <Col span={6}>
                                <Input onChange={(e) => setDistributeFuelCount(e)} value={distributeFuelCount} placeholder='燃料分发数量'></Input>
                            </Col>

                            <Col span={6}>
                                <TextArea onChange={(e) => setDistributeFuelArray(e)} value={distributeFuelArray} placeholder='分发地址, 一行一个'></TextArea>
                            </Col>
                        </Row>

                    </Card>

                    <Card title='MGB配置' style={{ marginBottom: 20 }}>

                        <Row gutter={[16, 16]}>

                            <Col span={6}>
                                <Input onChange={(e) => setCollectMGBAddress(e)} value={collectMGBAddress} placeholder='MGB归集地址'></Input>
                            </Col>

                            <Col span={6}>
                                <Input onChange={(e) => setCollectMGBCount(e)} value={collectMGBCount} placeholder='MGB归集数量'></Input>
                            </Col>
                        </Row>

                    </Card>
                </Collapse.Panel>
            </Collapse>






            <Tabs type="line">
                <TabPane tab={`前置准备-已导入钱包数量(${localStorage.getItem("pre_wallet") === null ? 0 : JSON.parse(localStorage.getItem("pre_wallet")).length})`} itemKey="1">
                    <ButtonGroup theme="borderless">
                        <Button onClick={genWallet} type='primary' style={{ marginTop: 6, marginRight: 8 }}>1.批量生成钱包</Button>
                        <Button onClick={() => showDialog('pre')} type='primary' style={{ marginTop: 6, marginRight: 8 }}>2.导入钱包</Button>
                        <Button onClick={() => collectAsset('gold')} type='primary' style={{ marginTop: 6, marginRight: 8 }}>3.金币归集</Button>
                        <Button onClick={() => distributeAsset('gold')} theme='solid' type='primary' style={{ marginTop: 6, marginRight: 8 }}>4.金币分发</Button>
                        <Button onClick={() => collectAsset('fuel')} theme='solid' type='primary' style={{ marginTop: 6, marginRight: 8 }}>5.燃料归集</Button>
                        <Button onClick={() => distributeAsset('fuel')} theme='solid' type='primary' style={{ marginTop: 6, marginRight: 8 }}>6.燃料分发</Button>
                        <Button onClick={() => buildLou(1)} theme='solid'  type='primary' style={{ marginTop: 6, marginRight: 8 }}>7.构建一层</Button>
                        <Button onClick={() => buildLou(2)} theme='solid'  type='primary' style={{ marginTop: 6, marginRight: 8 }}>8.构建二层</Button>
                        <Button onClick={() => collectAsset('mgb')} theme='solid' type='primary' style={{ marginTop: 6, marginRight: 8 }}>9.MGB归集</Button>
                        <Button onClick={() => queryAsset(1)} theme='solid' type='primary' style={{ marginTop: 6, marginRight: 8 }}>10.资产查询</Button>
                    </ButtonGroup>
                    <h3>执行日志 <Button onClick={clearPreLogs} size='small' theme='solid' type='danger'>清空日志</Button></h3>
                    <div style={{ border: '1px solid var(--semi-color-border)' }}>
                        <TextArea rows={20} value={genWalletLogs} readOnly />
                    </div>
                </TabPane>
                <TabPane tab="种菜收菜" itemKey="2">
                    <ButtonGroup theme="borderless">
                        <Button onClick={()=>showDialog('after')} icon={<IconImport />} type='primary' style={{ marginTop: 6, marginRight: 8 }}>导入钱包</Button>
                        <Button onClick={showWallteDialog} icon={<IconHistogram />} type='secondary' style={{ marginTop: 6, marginRight: 8 }}>查看已导入钱包</Button>
                        <Button theme='solid' onClick={() => fetchZhong(1)} type='primary' style={{ marginTop: 6, marginRight: 8 }}>种一层菜</Button>
                        <Button theme='solid' onClick={() => fetchZhong(2)} type='primary' style={{ marginTop: 6, marginRight: 8 }}>种二层菜</Button>

                        <Button theme='solid' onClick={() => fetchShou(1)} type='tertiary' style={{ marginTop: 6, marginRight: 8 }}>收一层菜</Button>
                        <Button theme='solid' onClick={() => fetchShou(2)} type='tertiary' style={{ marginTop: 6, marginRight: 8 }}>收二层菜</Button>
                    </ButtonGroup>
                    <h3>执行日志</h3>
                    <div style={{ border: '1px solid var(--semi-color-border)' }}>
                        <TextArea rows={20} value={logs} readOnly showClear />
                    </div>
                </TabPane>

                {/* <TabPane tab="资金溯源" itemKey="3">
                <Input  placeholder = { '0x0999....9999' } onChange={setAddress} value={address} style={{ width: 720, marginTop: 12, marginBottom: 12 }} />
                <Button style={{ marginLeft: 20 }} onClick={fetchTraceData} type="primary" htmlType="submit">查询</Button>
                <Table loading={loading} columns={ columnsTrace } dataSource={ trace.children } pagination={true} />
            </TabPane> */}
            </Tabs>
        </>
    );
}

export default App;