import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { Layout,   Nav, Button, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';
import { IconBell, IconHelpCircle, IconBytedanceLogo, IconHome, IconHistogram, IconLive, IconSetting } from '@douyinfe/semi-icons';

import Erc from '../pages/erc'

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layoutest />}>
          <Route index element={<Erc />} />
          <Route path="erc" element={<Erc />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layoutest() {

const { Header, Footer, Sider, Content } = Layout;

  return (
    <div>
           <Layout style={{ border: '1px solid var(--semi-color-border)' }}>
                <Nav
                    mode = "horizontal"
                    defaultSelectedKeys={['erc']}
                    header={{
                        text: '收菜平台',
                    }}
               
                    renderWrapper={({ itemElement, isSubNav, isInSubNav, props }) => {
                    const routerMap = {
                        erc: "/erc"
                    };
                    return (
                        <Link
                        style={{ textDecoration: "none" }}
                        to={routerMap[props.itemKey]}
                        >
                        {itemElement}
                        </Link>
                    );
                    }}
                    items={[
                    { itemKey: "erc", text: "机器人" },
                    // { itemKey: "trc", text: "波场链" }
                    ]}
                ></Nav>
            <Layout>
                {/* <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}></Header> */}
                <Content
                    style={{
                        padding: '24px',
                        backgroundColor: 'var(--semi-color-bg-0)',
                        height: '100hv' 
                    }}
                >
                    {/* <Breadcrumb
                        style={{
                            marginBottom: '24px',
                        }}
                        routes={['平台']}
                    /> */}
                    <Outlet />
                </Content>
                <Footer
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        color: 'var(--semi-color-text-2)',
                        backgroundColor: 'rgba(var(--semi-grey-0), 1)',
                    }}
                >
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                    </span>
                    <span>
                        <span style={{ marginRight: '24px' }}>平台客服</span>
                        <span>反馈建议</span>
                    </span>
                </Footer>
            </Layout>
        </Layout>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>暂无该页面</h2>
      <p>
        <Link to="/">返回首页</Link>
      </p>
    </div>
  );
}
